import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Link } from '../components/Link';
import { Main } from '../components/Main';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import videoURL from '../videos/uagi.mp4';
import './style.css'

const styles = theme => ({
  root: {}
});

class About extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <video controls src={videoURL} poster="/images/poster.png" style={{ width: '100%', height: '95%', background: '#000' }}/>
      </Main>
    );
  }
}

export default withStyles(styles)(About);
